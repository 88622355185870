<template>
  <div class="w-100">
    <!--begin::Content header-->
    <div class="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">

    </div>
    <!--end::Content header-->

    <!--begin::Signin-->
    <div class="row">
      <div class="col-12 col-lg-8 offset-lg-2 col-xl-6 offset-xl-3 col-xxl-4 offset-xxl-4 login-form login-signin">
        <div class="text-center mb-10 mb-lg-20">
          <h3 class="font-size-h1">Sign up</h3>
          <p class="text-muted font-weight-semi-bold">
            Enter your details below
          </p>
        </div>

        <div class="mt-n10 mb-10">
          <div class="d-block alert alert-custom alert-light-info">
            <div class="alert-text">
              Only use this feature if you are <strong>sunbed owner</strong> or <strong>operator</strong>.<br />
              If you are a Luxura distributor, please contact your account manager at VDL Hapro to receive credentials and support if necessary..
            </div>
          </div>
        </div>

        <!-- begin::Error alert -->
        <div
          v-if="isErrorActive"
          class="mt-20 mb-10"
        >
          <error-translation-alert
            ref="errorTranslationAlert"
            variant="light-danger"

            :error="activeError"

            class="mt-n10 mb-10"
          />
        </div>
        <!-- end::Error alert -->

        <!--begin::Form-->
        <form class="form" @submit.stop.prevent="onSubmit">
          <div class="form-group">
            <input
              ref="moduleIDInput"
              type="text"
              placeholder="MLM ID *"
              :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.moduleID.$error }]"
              :disabled="isLoadingForm"
              v-model="$v.form.moduleID.$model"
            />
          </div>

          <div class="form-group">
            <input
              ref="modulePSKInput"
              type="password"
              placeholder="MLM PSK *"
              :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.modulePSK.$error }]"
              :disabled="isLoadingForm"
              v-model="$v.form.modulePSK.$model"
            />
          </div>

          <div class="separator separator-solid mt-n3 mb-5" />

          <div class="form-group">
            <input
              ref="emailInput"
              type="email"
              placeholder="Email address *"
              :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.email.$error }]"
              :disabled="isLoadingForm"
              v-model="$v.form.email.$model"
            />
          </div>

          <div class="form-group">
            <input
              ref="companyInput"
              type="text"
              placeholder="Company *"
              :class="['form-control form-control-solid h-auto py-5 px-6', { 'is-invalid': $v.form.company.$error }]"
              :disabled="isLoadingForm"
              v-model="$v.form.company.$model"
            />
          </div>

          <!--begin::Action-->
          <div class="form-group d-flex flex-wrap justify-content-between align-items-center">
            <router-link to="/login" class="text-dark-60 text-primary text-hover-primary my-3 mr-2">
              Already have an account?
            </router-link>
            <button
              ref="submitButton"
              class="btn btn-primary font-weight-bold px-9 py-4 my-3 font-size-3"
              :disabled="isLoadingForm"
            >
              Sign up
            </button>
          </div>
          <!--end::Action-->
        </form>
        <!--end::Form-->
      </div>
      <!--end::Signin-->
    </div>
  </div>
</template>

<style lang="scss" scoped>
.spinner.spinner-right {
  padding-right: 3.5rem !important;
}
</style>

<script>
import { validationMixin } from 'vuelidate';
import { required, email } from 'vuelidate/lib/validators';
import { validations, errorHandling, errorComponentMixin } from '@vedicium/core-vue';
import errorTranslationAlert from '@/components/errors/translation.alert.vue';

export default {
  mixins: [validationMixin, errorComponentMixin],
  name: 'login',
  components: {
    errorTranslationAlert,
  },
  data () {
    return {
      isLoadingForm: false,
      form: {
        moduleID: null,
        modulePSK: null,

        email: null,
        company: null,
      },
    };
  },
  validations: {
    form: {
      moduleID: {
        required,
      },
      modulePSK: {
        required,
      },

      email: {
        required,
        email,
      },
      company: {
        required,
      },
    },
  },

  async mounted () {
    this.$set(this.form, 'moduleID', this.$route.query.module || null);
    this.$set(this.form, 'modulePSK', this.$route.query.module_psk || null);

    await this.$nextTick();

    // Focus on right input
    switch (true) {
      case !this.form.moduleID:
        this.$refs.moduleIDInput.focus();
        break;
      case !this.form.modulePSK:
        this.$refs.modulePSKInput.focus();
        break;
      default:
        this.$refs.emailInput.focus();
        break;
    }
  },

  methods: {
    async validate () {
      const validationArray = [this.$v];

      // Run the validations
      await validations.validateArray(validationArray);
    },
    async resetForm () {
      this.form = {
        email: null,
        password: null,
      };

      await this.$nextTick();

      this.$v.$reset();
    },
    async onSubmit () {
      errorHandling.clear({ component: this });
      this.$set(this, 'isLoadingForm', true);
      this.$refs.submitButton.classList.add('spinner', 'spinner-light', 'spinner-right');

      try {
        await this.validate();
        await this.$ws.post('/auth/sign-up', {
          body: {
            module: {
              id: this.form.moduleID,
              psk: this.form.modulePSK,
            },
            email: this.form.email,
            company: this.form.company,
          },
        }, {
          skip_auth: true,
        });
      } catch (e) {
        errorHandling.handle(e, { component: this });
        console.error(e);
        return;
      } finally {
        this.$set(this, 'isLoadingForm', false);
        this.$refs.submitButton.classList.remove('spinner', 'spinner-light', 'spinner-right');
      }
    },
  },
};
</script>
